<template>
  <Page>
    <template v-slot:help>
      <NotionHelp topic="shipments" />
    </template>
    <template v-slot:toolbar-buttons>
      <ButtonAction
        :action="() => modal.export.opened = true"
        icon="mdi-file-export-outline"
        text="Exporter les colis externes à traiter"
      />
      <ButtonLink
        href="/shipments/tracking/import"
        icon="mdi-file-upload"
        text="Importer des N° de suivi externe"
      />
    </template>
    <template v-slot:content>
      <ShipmentFilters @onFiltersChanged="onFiltersChanged" />
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :item-class="rowClass"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
      
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
      
          <template v-slot:item.edition="{ item }">
            <v-btn
              icon
              :href="'/shipments/' + item.id"
            >
              <v-icon
                color="blue lighten-1"
              >
                mdi-pencil-outline
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.type="{ item }">
            {{ $t('views.shipment.enum.type.' + item.type) }}
            <v-icon
              v-if="item.warehouse?.internal"
              small
              color="orange"
            >
              mdi-home-account
            </v-icon>
          </template>
          <template v-slot:item.tracking_number="{ item }">
            {{ item.tracking_number }}
            <a
              v-if="item.tracking_url"
              :href="item.tracking_url"
              target="_blank"
            >
              <v-icon
                small
                color="blue lighten-1"
              >mdi-open-in-new</v-icon>
            </a>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ item.created_datetime | dateSimple }}
          </template>
          <template v-slot:item.status="{ item }">
            <StatusChip :status="item.status" />
          </template>
          <template v-slot:item.provider="{ item }">
            <ProviderIcon :provider="item.provider" />
          </template>
          <template v-slot:item.carrier="{ item }">
            <CarrierIcon :carrier="item.carrier" />
          </template>
          <template v-slot:item.sender="{ item }">
            {{ item.sender_address.recipient_name }} {{ countryCodeEmoji(item.sender_address.country_code) }}
          </template>
          <template v-slot:item.recipient="{ item }">
            {{ item.recipient_address.recipient_name }} {{ countryCodeEmoji(item.recipient_address.country_code) }}
          </template>
          <template v-slot:item.errors="{ item }">
            <div v-if="item.last_error">
              <v-icon
                color="red"
                :alt="item.last_error.message"
                :title="item.last_error.message"
              >
                mdi-alert-box
              </v-icon>
              {{ item.last_error.created_at | dateSimple }}
            </div>
            <div v-if="item.lost">
              <v-icon
                color="red"
                alt="Colis perdu"
                title="Colis perdu"
              >
                mdi-truck-alert
              </v-icon>
              Colis perdu
            </div>
            <div v-if="item.deleted_at">
              <v-icon
                color="red"
                alt="Supprimé"
                title="Supprimé"
              >
                mdi-trash-can
              </v-icon>
              {{ item.deleted_at | dateSimple }}
            </div>
          </template>
        </v-data-table>
      </v-sheet>
      <ExportUntrackedShipmentsModal :opened.sync="modal.export.opened" />
    </template>
  </Page>
</template>

<script>
import { getQueryAsArray, getQueryString } from '@/helpers/queryString'
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import ButtonLink from '@/components/base/Toolbar/ButtonLink.vue'
import CarrierIcon from '@/components/base/Shipment/CarriererIcon.vue'
import { countryCodeEmoji } from '@/helpers/countries'
import ExportUntrackedShipmentsModal from '@/views/Shipments/Modal/ExportUntrackedShipments.vue'
import NotionHelp from '@/components/core/NotionHelp.vue'
import Page from '@/components/core/Page.vue'
import ProviderIcon from '@/components/base/Shipment/ProviderIcon.vue'
import ShipmentFilters from '@/views/Shipments/Filters.vue'
import StatusChip from '@/components/base/Shipment/StatusChip.vue'

export default {
  name: 'ShipmentsList',
  components: {
    ExportUntrackedShipmentsModal,
    NotionHelp,
    ButtonAction,
    ButtonLink,
    Page,
    CarrierIcon,
    ProviderIcon,
    ShipmentFilters,
    StatusChip,
  },
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      filters: {
        store_id: this.$route.query['store_id'],
        internal: this.$route.query['internal'],
        reference: this.$route.query['reference'],
        tracking_number: this.$route.query['tracking_number'],
        recipient_email: this.$route.query['recipient_email'],
        errorOnly: !!this.$route.query['errorOnly'],
        deletedIncluded: !!this.$route.query['deletedIncluded'],
        lostOnly: !!this.$route.query['lostOnly'],
        'type[]': getQueryAsArray(this.$route.query, 'type[]'),
        'status[]': getQueryAsArray(this.$route.query, 'status[]'),
        'provider[]': getQueryAsArray(this.$route.query, 'provider[]'),
        'carrier[]': getQueryAsArray(this.$route.query, 'carrier[]'),
        'created_at[after]': this.$route.query['created_at[after]'],
        'created_at[before]': this.$route.query['created_at[before]'],
        product_id: this.$route.query['product_id'],
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      table: {
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
        headers: [
          {
            value: 'edition',
            text: '',
            width: 30,
            sortable: false,
          },
          {
            value: 'type',
            text: 'Type d\'envoi',
            sortable: true,
          }, {
            value: 'status',
            text: 'Statut',
            sortable: true,
          }, {
            value: 'reference',
            text: 'Réference',
            sortable: true,
          }, {
            value: 'created_at',
            text: 'Date',
            sortable: true,
          },   {
            value: 'provider',
            text: 'Logisticien',
            align: 'center',
            sortable: true,
          }, {
            value: 'carrier',
            text: 'Transporteur',
            align: 'center',
            sortable: true,
          }, {
            value: 'tracking_number',
            text: 'N° de suivi',
            sortable: true,
          }, {
            value: 'sender',
            text: 'Expéditeur',
            sortable: true,
          }, {
            value: 'recipient',
            text: 'Destinataire',
            sortable: true,
          }, {
            value: 'errors',
            text: '-',
            sortable: false,
          },
        ],
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortBy: ['created_at'],
          sortDesc: [true],
          multiSort: false,
        },
      },
      modal: {
        export: {
          opened: false,
        },
      },
    }
  },
  computed: {
    queryString: function () {
      return 'v3/shipments?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 1
    },
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'ShipmentsList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    countryCodeEmoji,
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'ShipmentsList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      this.$axios.get(this.queryString, { cancelToken: axiosSource.token })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
        .finally(() => {
          this.request = null
          this.result.loading = false
        })
    },
    rowClass(item) {
      return item.deleted_at === null ? '' : 'v-data-table__item__deleted'
    },
  },
}
</script>

<style lang="scss">
  .v-data-table__wrapper .v-data-table__item__deleted {
    background-color: #ECECEB !important;
    font-style: italic !important;;
  }
</style>
