export function toRfc3339(date) {
  date = date.toISOString()

  const replaceZ = date.charAt(date.length - 1) === 'Z'

  date = date.substring(0, date.lastIndexOf('.'))

  if (replaceZ) {
    date += '+00:00'
  }

  return date
}
