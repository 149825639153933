export function downloadResponse(response) {
  const data = response.data
  const mime = response.headers['content-type'] || 'application/octet-stream'

  let filename = null
  let disposition = response.headers['content-disposition']
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches !== null && matches[1]) {
      // does not support utf8 only filename, it may produce a weird result
      // filename*=utf-8''rerum_untracked%F0%9F%98%84_shipments.csv => utf-8rerum_untracked%F0%9F%98%84_shipments.csv
      filename = matches[1].replace(/['"]/g, '')
    }
  }

  if (!filename) {
    const blob = new Blob([data], { type: mime + ';charset=utf-8,' })
    const url = window.URL.createObjectURL(blob)

    window.open(url)

    return
  }

  const blob = new Blob([data], { type: mime })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // For IE
    window.navigator.msSaveBlob(blob, filename)
  } else {
    // For other browsers
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}
