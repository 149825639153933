<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card :loading="loading">
      <v-card-title>
        <v-icon left>
          mdi-file-export-outline
        </v-icon>
        Exporter les colis externes à traiter
        <NotionHelp topic="shipments-export-untracked-external-shipments" />
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="download()">
          <v-row dense>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <StoreSelector v-model="storeId" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-autocomplete
                v-model="format"
                :items="formats"
                label="formats"
                dense
                hide-details
                item-text="name"
                item-value="id"
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="6"
              md="6"
              sm="6"
              xs="6"
            >
              <v-menu
                v-model="datePickers.from.show"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="from"
                    label="Du"
                    dense
                    outlined
                    hide-details
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="from"
                  no-title
                  @input="datePickers.from.show = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="6"
              sm="6"
              xs="6"
            >
              <v-menu
                v-model="datePickers.to.show"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="to"
                    label="Au"
                    dense
                    outlined
                    hide-details
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="to"
                  no-title
                  @input="datePickers.to.show = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="download()"
        >
          <v-icon left>
            mdi-file-export-outline
          </v-icon>Exporter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { downloadResponse } from '@/helpers/file'
import NotionHelp from '@/components/core/NotionHelp.vue'
import snackbarMixin from '@/mixins/snackbar'
import StoreSelector from '@/components/base/StoreSelector.vue'
import { toRfc3339 } from '@/helpers/date'

export default {
  name: 'ExportUntrackedShipmentsModal',
  components: { NotionHelp, StoreSelector },
  mixins: [snackbarMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      storeId: null,
      format: 'default',
      from: null,
      to: null,
      dateRange: null,

      formats: [
        {
          id: 'default',
          name: 'Default',
        },
        {
          id: 'coliship',
          name: 'Coliship',
        },
        {
          id: 'effitrace',
          name: 'Effitrace',
        },
      ],
      datePickers: {
        from: {
          show: false,
        },
        to: {
          show: false,
        },
      },
    }
  },
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
  },
  methods: {
    close() {
      this.loading = false
      this.openedValue = false

      this.storeId = null
      this.format = 'default'
      this.from = null
      this.to = null
    },
    async download() {
      this.loading = true

      try {
        const from = null !== this.from ? new Date(this.from) : null

        let to = null
        if (null !== this.to) {
          to = new Date(this.to)
          to.setDate(to.getDate() + 1)
        }

        downloadResponse(
          await this.$axios.post('export/untracked-external-shipments', {
            store_id: this.storeId,
            format: this.format,
            from: null !== from ? toRfc3339(from) : null,
            to: null !== to ? toRfc3339(to) : null,
          })
        )
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return
        }
        // eslint-disable-next-line no-console
        console.log(error)
        let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? error
        this.snackbarError(this.$i18n.t('global.message.error', { error: error_message }))
        this.loading = false
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
